import React, { useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartLine } from '@fortawesome/free-solid-svg-icons';
import Select from 'react-select'

import api from '../../../services/api';

function Menu() {
    const { register: regDespEmAbertoPorVencimento, handleSubmit: hanDespEmAbertoPorVencimento } = useForm();
    const { register: regComissaoSobrePedidosEmAbertoPorDataDeVenciemento, handleSubmit: hanComissaoSobrePedidosEmAbertoPorDataDeVenciemento } = useForm();
    const { register: regReceitasXDespesasPorDataDeVenciemento, handleSubmit: hanReceitasXDespesasPorDataDeVenciemento } = useForm();
    const { register: regChequePorDataDeVenciemento, handleSubmit: hanChequePorDataDeVenciemento } = useForm();
    const [relatorios, setRelatorios] = useState([]);
    const { register: register4, handleSubmit: handleSubmit4 } = useForm();
    const { register: register5, handleSubmit: handleSubmit5, errors: errors5 } = useForm();
    const { register: register6, handleSubmit: handleSubmit6 } = useForm();
    const { register: register7, handleSubmit: handleSubmit7, errors: errors7 } = useForm();
    const [status7, setStatus7] = useState([]);
    const [clientesFornecedores, setClientesFornecedores] = useState([]);
    const [naturezasFinanceiras, setNaturezasFinanceiras] = useState([]);
    const [centrosCustos, setCentrosCustos] = useState([]);
    const [pedidosCobraComissao, setPedidosCobraComissao] = useState([]);

    useEffect(() => {
        async function getParametros() {
            await api.get('/Relatorio/listarRelatorios').then(response => {
                setRelatorios(response ? response.data : null);
            });

            await api.get('/ClienteFornecedor/todos').then(response => {
                setClientesFornecedores(response ? response.data : null);
            });

            await api.get('/NaturezaFinanceira/todos').then(response => {
                setNaturezasFinanceiras(response ? response.data : null);
            });

            await api.get('/CentroCusto/todos').then(response => {
                setCentrosCustos(response ? response.data : null);
            });

            await api.get(`/Pedido/obterFiltro?statusPedido=4`).then(response => {
                setPedidosCobraComissao(response ? response.data : null);
            });
        }
        getParametros();
    }, []);

    const onSubmitDespEmAbertoPorVencimento = async (data) => {
        await api.get(`/RelatoriosFinanceiros/DespesasEmAbertoPorDataDeVencimento/${data.dataInicial}/${data.dataFinal}`, {
            method: 'GET',
            responseType: 'blob'
        }).then(response => {
            const file = new Blob([response.data], { type: 'application/pdf' });
            const fileURL = URL.createObjectURL(file);
            window.open(fileURL);
        });
    }

    const onComissaoSobrePedidosEmAbertoPorDataDeVenciemento = async (data) => {
        await api.get(`/RelatoriosFinanceiros/ComissaoSobrePedidosEmAbertoPorDataDeVenciemento/${data.dataInicial}/${data.dataFinal}`, {
            method: 'GET',
            responseType: 'blob'
        }).then(response => {
            const file = new Blob([response.data], { type: 'application/pdf' });
            const fileURL = URL.createObjectURL(file);
            window.open(fileURL);
        });
    }

    const onReceitasXDespesasPorDataDeVenciemento = async (data) => {
        await api.get(`/RelatoriosFinanceiros/ReceitasXDespesasDataDeVenciemento/${data.dataInicial}/${data.dataFinal}`, {
            method: 'GET',
            responseType: 'blob'
        }).then(response => {
            const file = new Blob([response.data], { type: 'application/pdf' });
            const fileURL = URL.createObjectURL(file);
            window.open(fileURL);
        });
    }

    const onChequesPorDataDeVenciemento = async (data) => {
        console.log(data);
        let s1 = 0;
        let s2 = 0;
        if (data.jaDepositar == true){
            s2 = 2; 
        }
        
        if (data.aDepositar == true){
            s1 = 1;
        }

        await api.get(`RelatoriosCheques/ChequesPorDataDeVencimento?dataInicial=${data.dataInicial}&dataFinal=${data.dataFinal}&s1=${s1}&s2=${s2}`, {
            method: 'GET',
            responseType: 'blob'
        }).then(response => {
            const file = new Blob([response.data], { type: 'application/pdf' });
            const fileURL = URL.createObjectURL(file);
            window.open(fileURL);
        });
    }

    const onSubmitCobraComissao = async (data) => {
        await api.post('/RelatorioPedidos/cobraComissao', status7, {
            method: 'POST',
            responseType: 'blob'
        }).then(response => {
            if (response) {
                const file = new Blob([response.data], { type: 'application/pdf' });
                const fileURL = URL.createObjectURL(file);
                window.open(fileURL);
            }
        });
    }

    const onSubmitLancamentosPorClienteFornecedor = async (data) => {
        window.open(`/relatorio/lancamentosEmAberto/${data.clienteFornecedorId}`);
    }

    const onSubmitLancamentosPorNaturezaFinanceira = async (data) => {
        window.open(`/relatorio/lancamentosPorNF/${data.naturezaFinanceiraId}/${data.tipoData}/${data.dataInicial}/${data.dataFinal}/${data.clienteFornecedorId}`);
    }

    const onSubmitLancamentosPorCentroCusto = async (data) => {
        window.open(`/relatorio/lancamentosPorCC/${data.centroCustoId}/${data.tipoData}/${data.dataInicial}/${data.dataFinal}`);
    }

    const handleChange7 = (selectedOptions) => {
        console.log(selectedOptions.map(({ value }) => value))
        setStatus7(selectedOptions.map(({ value }) => value))
    }

    return (
        <div className="container" style={{ marginTop: 20 }}>
            <div>
                <div id="accordion">
                    {relatorios.filter(r => r.nome === 'despesas_em_aberto_por_data_de_vencimento').length > 0 &&
                        <div className="card">
                            <div className="card-header" id="headingOne" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                <FontAwesomeIcon className="ml-2" icon={faChartLine} /> Despesas em aberto por data de vencimento
                            </div>
                            <div id="collapseOne" className="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                                <div className="card-body">
                                    <form key={1} onSubmit={hanDespEmAbertoPorVencimento(onSubmitDespEmAbertoPorVencimento)}>
                                        <div className="form-row">
                                            <div className="form-group col-md-2">
                                                <label>Data Inicial</label>
                                                <input type="date" name="dataInicial" className="form-control" ref={regDespEmAbertoPorVencimento()} id="dataInicial" placeholder="Data Inicial" required />
                                            </div>
                                            <div className="form-group col-md-2">
                                                <label>Data Final</label>
                                                <input type="date" name="dataFinal" className="form-control" ref={regDespEmAbertoPorVencimento()} id="dataFinal" placeholder="Data Final" required />
                                            </div>
                                            <div className="form-group col-md-2" style={{ paddingTop: "33px" }}>
                                                <button type="submit" className="form-control btn btn-primary">Visualizar</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    }

                    {relatorios.filter(r => r.nome === 'comissao_sobre_pedidos_em_aberto_por_data_de_venciemento').length > 0 &&
                        <div className="card">
                            <div className="card-header" id="headingTwo" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">
                                <FontAwesomeIcon className="ml-2" icon={faChartLine} /> Receita de Comissão Sobre Pedidos / Em Aberto / Por data de Vencimento
                            </div>
                            <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                                <div className="card-body">
                                    <form key={1} onSubmit={hanComissaoSobrePedidosEmAbertoPorDataDeVenciemento(onComissaoSobrePedidosEmAbertoPorDataDeVenciemento)}>
                                        <div className="form-row">
                                            <div className="form-group col-md-2">
                                                <label>Data Inicial</label>
                                                <input type="date" name="dataInicial" className="form-control" ref={regComissaoSobrePedidosEmAbertoPorDataDeVenciemento()} id="dataInicial" placeholder="Data Inicial" required />
                                            </div>
                                            <div className="form-group col-md-2">
                                                <label>Data Final</label>
                                                <input type="date" name="dataFinal" className="form-control" ref={regComissaoSobrePedidosEmAbertoPorDataDeVenciemento()} id="dataFinal" placeholder="Data Final" required />
                                            </div>
                                            <div className="form-group col-md-2" style={{ paddingTop: "33px" }}>
                                                <button type="submit" className="form-control btn btn-primary">Visualizar</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    }

                    {relatorios.filter(r => r.nome === 'receitas_x_despesas_por_data_de_venciemento').length > 0 &&
                        <div className="card">
                            <div className="card-header" id="headingThree" data-toggle="collapse" data-target="#collapseThree" aria-expanded="true" aria-controls="collapseThree">
                                <FontAwesomeIcon className="ml-2" icon={faChartLine} /> Receitas X Despesas / Por data de Vencimento
                            </div>
                            <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordion">
                                <div className="card-body">
                                    <form key={1} onSubmit={hanReceitasXDespesasPorDataDeVenciemento(onReceitasXDespesasPorDataDeVenciemento)}>
                                        <div className="form-row">
                                            <div className="form-group col-md-2">
                                                <label>Data Inicial</label>
                                                <input type="date" name="dataInicial" className="form-control" ref={regReceitasXDespesasPorDataDeVenciemento()} id="dataInicial" placeholder="Data Inicial" required />
                                            </div>
                                            <div className="form-group col-md-2">
                                                <label>Data Final</label>
                                                <input type="date" name="dataFinal" className="form-control" ref={regReceitasXDespesasPorDataDeVenciemento()} id="dataFinal" placeholder="Data Final" required />
                                            </div>
                                            <div className="form-group col-md-2" style={{ paddingTop: "33px" }}>
                                                <button type="submit" className="form-control btn btn-primary">Visualizar</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    }

                    {relatorios.filter(r => r.nome === 'lacamentos_por_cliente_fornecedor').length > 0 &&
                        <div className="card">
                            <div className="card-header" id="headingFour" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                <FontAwesomeIcon className="ml-2" icon={faChartLine} /> Lançamentos por Cliente/Fornecedor - Comissão
                            </div>
                            <div id="collapseFour" className="collapse" aria-labelledby="headingFour" data-parent="#accordion">
                                <div className="card-body">
                                    <form key={4} onSubmit={handleSubmit4(onSubmitLancamentosPorClienteFornecedor)}>
                                        <div className="form-row">
                                            <div className="form-group col-md-10">
                                                <label>Cliente/Fornecedor</label>
                                                <select id="clienteFornecedorId" name="clienteFornecedorId" ref={register4()} className="form-control" required>
                                                    {
                                                        clientesFornecedores && clientesFornecedores.map((clienteFornecedor, index) => (
                                                            <option key={index} value={clienteFornecedor.id}>{clienteFornecedor.nome}</option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                            <div className="form-group col-md-2" style={{ paddingTop: "33px" }}>
                                                <button type="submit" className="form-control btn btn-primary">Visualizar</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    }

                    {relatorios.filter(r => r.nome === 'lacamentos_por_natureza_financeira').length > 0 &&
                        <div className="card">
                            <div className="card-header" id="headingFive" data-toggle="collapse" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                <FontAwesomeIcon className="ml-2" icon={faChartLine} /> Lançamentos por Natureza Financeira
                            </div>
                            <div id="collapseFive" className="collapse" aria-labelledby="headingFice" data-parent="#accordion">
                                <div className="card-body">
                                    <form key={4} onSubmit={handleSubmit5(onSubmitLancamentosPorNaturezaFinanceira)}>
                                        <div className="form-row">
                                            <div className="form-group col-md-4">
                                                <label>Natureza Financeira</label>
                                                <select id="naturezaFinanceiraId" name="naturezaFinanceiraId" ref={register5()} className="form-control" required>
                                                    <option value=""></option>
                                                    {
                                                        naturezasFinanceiras && naturezasFinanceiras.map((naturezaFinanceira, index) => (
                                                            <option disabled={naturezaFinanceira.sintetico} key={index} value={naturezaFinanceira.id}>{naturezaFinanceira.descricao}</option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                            <div className="form-group col-md-8">
                                                <label>Cliente/Fornecedor</label>
                                                <select id="clienteFornecedorId" name="clienteFornecedorId" ref={register5()} className="form-control" required>
                                                    <option value="00000000-0000-0000-0000-000000000000">Todos</option>
                                                    {
                                                        clientesFornecedores && clientesFornecedores.map((clienteFornecedor, index) => (
                                                            <option key={index} value={clienteFornecedor.id}>{clienteFornecedor.nome}</option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                            <div className="form-group col-md-2">
                                                <label>Tipo data</label>
                                                <select id="tipoData" name="tipoData" ref={register5()} className="form-control" required>
                                                    <option value=""></option>
                                                    <option value="1">Vencimento</option>
                                                    <option value="2">Baixa</option>
                                                </select>
                                                {errors5.tipoData && <span className="text-danger ml-2">*</span>}
                                            </div>
                                            <div className="form-group col-md-2">
                                                <label>Data Inicial</label>
                                                <input type="date" name="dataInicial" className="form-control" ref={register5()} id="dataInicial" placeholder="Data Inicial" required />
                                            </div>
                                            <div className="form-group col-md-2">
                                                <label>Data Final</label>
                                                <input type="date" name="dataFinal" className="form-control" ref={register5()} id="dataFinal" placeholder="Data Final" required />
                                            </div>
                                            <div className="form-group col-md-2" style={{ paddingTop: "33px" }}>
                                                <button type="submit" className="form-control btn btn-primary">Visualizar</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    }

                    {relatorios.filter(r => r.nome === 'lacamentos_por_centro_custo').length > 0 &&
                        <div className="card">
                            <div className="card-header" id="headingSix" data-toggle="collapse" data-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                <FontAwesomeIcon className="ml-2" icon={faChartLine} /> Lançamentos por Centro de Custo
                            </div>
                            <div id="collapseSix" className="collapse" aria-labelledby="headingSix" data-parent="#accordion">
                                <div className="card-body">
                                    <form key={4} onSubmit={handleSubmit6(onSubmitLancamentosPorCentroCusto)}>
                                        <div className="form-row">
                                            <div className="form-group col-md-4">
                                                <label>Centro de Custo</label>
                                                <select id="centroCustoId" name="centroCustoId" ref={register6()} className="form-control" required>
                                                    <option value=""></option>
                                                    {
                                                        centrosCustos && centrosCustos.map((centroCusto, index) => (
                                                            <option key={index} value={centroCusto.id}>{centroCusto.nome}</option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                            <div className="form-group col-md-2">
                                                <label>Tipo data</label>
                                                <select id="tipoData" name="tipoData" ref={register6()} className="form-control" required>
                                                    <option value=""></option>
                                                    <option value="1">Vencimento</option>
                                                    <option value="2">Baixa</option>
                                                </select>
                                                {errors5.tipoData && <span className="text-danger ml-2">*</span>}
                                            </div>
                                            <div className="form-group col-md-2">
                                                <label>Data Inicial</label>
                                                <input type="date" name="dataInicial" className="form-control" ref={register6()} id="dataInicial" placeholder="Data Inicial" required />
                                            </div>
                                            <div className="form-group col-md-2">
                                                <label>Data Final</label>
                                                <input type="date" name="dataFinal" className="form-control" ref={register6()} id="dataFinal" placeholder="Data Final" required />
                                            </div>
                                            <div className="form-group col-md-2" style={{ paddingTop: "33px" }}>
                                                <button type="submit" className="form-control btn btn-primary">Visualizar</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    }

                    {relatorios.filter(r => r.nome === 'cobrar_comissao').length > 0 &&
                        <div className="card">
                            <div className="card-header" id="headingSeve" data-toggle="collapse" data-target="#collapseSeve" aria-expanded="false" aria-controls="collapseSeve">
                                <FontAwesomeIcon className="ml-2" icon={faChartLine} /> Cobrar Comissão
                            </div>
                            <div id="collapseSeve" className="collapse" aria-labelledby="collapseSeve" data-parent="#accordion">
                                <div className="card-body">
                                    <form key={4} onSubmit={handleSubmit7(onSubmitCobraComissao)}>
                                        <div className="form-row">
                                            <div className="form-group col-md">
                                                <label>Selecione os Pedidos</label>
                                                <Select
                                                    closeMenuOnSelect={false}
                                                    onChange={handleChange7}
                                                    isMulti
                                                    options={pedidosCobraComissao.map(p => ({ value: p.id, label: `${p.cliente.nome} / ${p.fornecedor.razaoSocial} / Nº: ${p.numero}` }))}
                                                />
                                            </div>
                                            <div className="form-group col-md-2" style={{ paddingTop: "33px" }}>
                                                <button type="submit" className="form-control btn btn-primary">Visualizar</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    }

                    {relatorios.filter(r => r.nome === 'cheques_por_data_de_venciemento').length > 0 &&
                        <div className="card">
                            <div className="card-header" id="headingEight" data-toggle="collapse" data-target="#collapseEight" aria-expanded="true" aria-controls="collapseEight">
                                <FontAwesomeIcon className="ml-2" icon={faChartLine} /> Cheques / Por data de Vencimento
                            </div>
                            <div id="collapseEight" className="collapse" aria-labelledby="headingEight" data-parent="#accordion">
                                <div className="card-body">
                                    <form key={1} onSubmit={hanChequePorDataDeVenciemento(onChequesPorDataDeVenciemento)}>
                                        <div className="form-row">
                                            <div className="form-group col-md-2">
                                                <label>Data Inicial</label>
                                                <input type="date" name="dataInicial" className="form-control" ref={regChequePorDataDeVenciemento()} id="dataInicial" placeholder="Data Inicial" required />
                                            </div>
                                            <div className="form-group col-md-2">
                                                <label>Data Final</label>
                                                <input type="date" name="dataFinal" className="form-control" ref={regChequePorDataDeVenciemento()} id="dataFinal" placeholder="Data Final" required />
                                            </div>
                                            <div className="form-group col-md-2">
                                                <label>Já Depositados</label>
                                                <input type="checkbox" name="jaDepositar" className="form-control" ref={regChequePorDataDeVenciemento()} id="jaDepositar" placeholder="Já Depositado" />
                                            </div>
                                            <div className="form-group col-md-2">
                                                <label>A Depositar</label>
                                                <input type="checkbox" name="aDepositar" className="form-control" ref={regChequePorDataDeVenciemento()} id="aDepositar" placeholder="A Depositar" />
                                            </div>
                                            <div className="form-group col-md-2" style={{ paddingTop: "33px" }}>
                                                <button type="submit" className="form-control btn btn-primary">Visualizar</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}

export default Menu;